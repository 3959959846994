import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2017-04-12",
  "title": "How to Tame the Cascade",
  "description": "Making CSS actually fun to work with",
  "cover": "/img/cover/dragon.jpg",
  "pic": "/img/dragon.jpg",
  "color": "#f14429"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Once upon a time there was a village named Cascadeville, named after the local dragon "Cascade". The people both loved and feared Cascade. This great beast protected the villagers against barbarian raiders, and fueled their furnaces with its great fire. But Cascade wasn't particularly careful where he walked or shot his flames, so the poor villagers didn't have a very long life expectancy. One day the people decided that something had to be done about this dragon. `}</p>
    <p>{`They devised harnesses to control his fire, and a large dragon leash to keep him safely contained. The village thrived and the people lived happily ever after.`}</p>
    <h2>{`The Cascade Problem`}</h2>
    <p>{`Just like the dragon in our (true) story, the C in CSS (Cascading Style Sheets) is powerful but dangerous. Unless properly managed it can quickly turn an exciting new codebase into crumbling ashes. I've seen more than one project where even the bravest developers wouldn't dare risking a change to the CSS. And as soon as something can't be easily changed or deleted, it can't be maintained. This leads to countless hacks, one-offs, and workarounds getting heaped on top of the failing foundation.`}</p>
    <h3>{`What Exactly is the Cascade?`}</h3>
    <p>{`The fact that styles "cascade" means that the final styles applied to an element can come from many different sources:`}</p>
    <ul>
      <li parentName="ul">{`user agent styles built into the browser`}</li>
      <li parentName="ul">{`stylesheets`}</li>
      <li parentName="ul">{`style tags`}</li>
      <li parentName="ul">{`parent elements' styles`}</li>
      <li parentName="ul">{`inline declarations`}</li>
      <li parentName="ul">{`user/extension overrides`}</li>
    </ul>
    <p>{`The browser runs a complex "cascade" algorithm to calculate exactly which style properties/values apply to each element. When there are duplicate properties (e.g. `}<inlineCode parentName="p">{`color`}</inlineCode>{`) defined it takes into account the stylesheet load order, `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity"
      }}>{`specificity`}</a>{`, parent styles (only for some properties), style location (style tags win over stylesheets, inline wins over style tags), whether or not the `}<inlineCode parentName="p">{`!important`}</inlineCode>{` bomb was dropped, etc. It's quite a lot to keep in your head while trying to build a layout. `}</p>
    <h3>{`Hey Who Broke my Stuff?`}</h3>
    <p>{`How many times have you been bitten by a CSS change made by another dev in a seemingly unrelated area of the project? This cascade problem is compounded by the use of global CSS names and well-meaning approaches that encourage code reuse. Building features and components that can have their styles impacted by other features and components is a brittle and error-prone way to work. Enough of getting burned by them flames!`}</p>
    <h2>{`The Cascade Solution`}</h2>
    <p>{`The cascade isn't going away anytime soon, but there are things we can do now to harness and isolate it, make it work for us instead of against us.`}</p>
    <h3>{`Reuse Components, not Styles`}</h3>
    <p>{`Most of us when we learn to code are taught that reusing code is a good thing. So we've been trying for over a decade to find a good way to reuse CSS. We even invented conventions like BEM, OOCSS, SMACSS, etc. to try to force it to work. `}</p>
    <p>{`We had a lot of similar problems in JavaScript until some very smart people built us better tools. By using ES2015 `}<inlineCode parentName="p">{`import`}</inlineCode>{` (or the webpack/rollup/browserify equivalent) we know exactly where everything in our app comes from thanks to `}<strong parentName="p">{`explicit imports`}</strong>{`. It turns out this traceability is hugely important and lets us refactor JS code with much more confidence. But CSS cheats. It bypasses the explicit import contract, impacting your UI in strange and unpredictable ways. `}</p>
    <p>{`The solution is to stop trying to reuse styles at all. When it comes to CSS, `}<strong parentName="p">{`isolation is more important than reuse`}</strong>{`. Focus instead on building UI components that can be reused throughout your application. Make components your new building block. The `}<a parentName="p" {...{
        "href": "/post/strong-components/"
      }}>{`component mindset`}</a>{` is one of the biggest advances in the web community in the last 5 years. Build components that `}<strong parentName="p">{`isolate`}</strong>{` their own styles, that don't use globals and that don't mess with anything outside of their boundry. Components that have to be explicitly imported to be used, so that you know what you'll impact when you edit/delete them. If your application is made up of a series of these strong components you'll avoid most of the cascade issues entirely, and have a codebase that is an asset rather than a liability.`}</p>
    <h3>{`How to Isolate Styles`}</h3>
    <p>{`Ok so how do we contain the dragon's flames (styles) to the component they belong to? There are a number of solutions that do the job for any of the popular JS frameworks. My friend Kent just released `}<a parentName="p" {...{
        "href": "https://github.com/paypal/glamorous"
      }}>{`glamorous`}</a>{` that I'm excited to check out. But my current favorite for React projects is `}<a parentName="p" {...{
        "href": "https://styled-components.com/"
      }}>{`styled-components`}</a>{`. You build your component as a self-contained entity, styles and all. Here is my entire `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Zombie`}</a>{` component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import styled from 'styled-components';
import Widget from './widget';

function Zombie(props) {
  const ZombieStyle = styled.div\`
    padding: 10px;
    width: \${this.props.width || 160}px;
    height: \${this.props.height || 308}px;
    z-index: 21;
  \`

  return (
    <ZombieStyle>
      <Widget config={this.props.config} />
    </ZombieStyle>
  )
}
`}</code></pre>
    <p>{`I'm able to reuse this component all over the place, with full confidence that it won't affect or be affected by other components in my project. All without having to think once about specificity, style order, or the cascade.`}</p>
    <h2>{`Other Advantages`}</h2>
    <p>{`In addition to pacifying the cascade dragon, `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` gives us a neat bag of tricks.`}</p>
    <h3>{`Full Power of JS`}</h3>
    <p>{`Because it's using `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals"
      }}>{`template literals`}</a>{` I'm able to adapt the styles based on dynamic configuration passed in through the component's props. It also means you can have dynamic paths for things like background images. In my flexbox zombies project I have an `}<inlineCode parentName="p">{`\${assets}`}</inlineCode>{` variable that gets set to `}<inlineCode parentName="p">{`/assets`}</inlineCode>{` (local) during development, then the full Netlify CDN path in production.`}</p>
    <h3>{`Composability`}</h3>
    <p>{`Since `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` are just components themselves, you can pass these style components around as props to other components, giving you a crazy level of composability.`}</p>
    <h3>{`One File Please`}</h3>
    <p>{`It's hard to describe how nice it is having your entire component in one place instead of three different (HTML/CSS/JS) files. Don't let the dated "separation of concerns" dogma keep you from this improved workflow.`}</p>
    <h3>{`Sane Way to Extend`}</h3>
    <p>{`You can also "extend" components, for those cases where you like the component you or a team member built but need a slight variation of it. This was possible in previous CSS approaches but was hard for the original author to know who is using/extending their component. With `}<inlineCode parentName="p">{`styled-components`}</inlineCode>{` you can't cheat: to use or extend a component you have to import it explicity:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Button from './ui/Button';

const ActionButton = styled(Button)\`
  background-color: rgba(255, 142, 109);
  transition-property: border;
  
  &:hover{
    border-width: 5px;
  }
\`
`}</code></pre>
    <p>{` `}<br parentName="p"></br>{`
`}{``}<br parentName="p"></br>{`
`}{`If you've been burned by CSS before I think you'll find this new approach removes a lot of pains. Time to show that old Cascade who is boss!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      